import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Login from "../../components/Login";
import {
  ReceptionLogin,
  ReceptionSendOtp,
  SuperAdminLogin,
  DesignerLogin,
  DesignerSendOtp,
  WorkShopLogin,
  WorkShopSendOtp,
  ManagerSendOtp,
  ManagerLogin,
  InventoryClerkLogin,
  InventoryClerkSendOtp,
  AccountantLogin,
  AccountantForgotPassword,
  AccountantSendOtp,
  CashierLogin,
  CashierForgotPassword,
  CashierSendOtp,
  SuperAdminSendOtp,
} from "../../graphql/mutation";
import { useEffect, useContext } from "react";
import { SidebarCtx } from "../../context";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const { setLoggedIn } = useContext(SidebarCtx);

  useEffect(() => {
    setLoggedIn(false);
  });

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const LoginPage = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box mt={10} p={2} minWidth={200} maxWidth={650} mx="auto">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="basic tabs"
        >
          <Tab label="Reception" {...a11yProps(0)} />
          <Tab label="Cashier" {...a11yProps(1)} />
          <Tab label="Designer" {...a11yProps(2)} />
          <Tab label="Work Shop" {...a11yProps(3)} />
          <Tab label="Inventory Clerk" {...a11yProps(4)} />
          <Tab label="Manager" {...a11yProps(5)} />
          <Tab label="Accountant" {...a11yProps(6)} />
          <Tab label="Super Admin" {...a11yProps(7)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Login
          user="Reception"
          mutation={{ login: ReceptionLogin, Forget: ReceptionSendOtp }}
        ></Login>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Login
          user="Cashier"
          mutation={{ login: CashierLogin, Forget: CashierSendOtp }}
        ></Login>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Login
          user="Designer"
          mutation={{ login: DesignerLogin, Forget: DesignerSendOtp }}
        ></Login>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Login
          user="WorkShop"
          mutation={{ login: WorkShopLogin, Forget: WorkShopSendOtp }}
        ></Login>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Login
          user="InventoryClerk"
          mutation={{
            login: InventoryClerkLogin,
            Forget: InventoryClerkSendOtp,
          }}
        ></Login>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Login
          user="Manager"
          mutation={{ login: ManagerLogin, Forget: ManagerSendOtp }}
        ></Login>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Login
          user="Accountant"
          mutation={{ login: AccountantLogin, Forget: AccountantSendOtp }}
        ></Login>
      </TabPanel>
      <TabPanel value={value} index={7}>
        <Login
          user="SuperAdmin"
          mutation={{ login: SuperAdminLogin, Forget: SuperAdminSendOtp }}
        ></Login>
      </TabPanel>
    </Box>
  );
};

export default LoginPage;
